import React from 'react';

import { Button, Form, FormControl, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import {HomeRounded, ScheduleRounded,WorkRounded,LinkedIn,GitHub,Gmail,Telegram} from '@material-ui/icons'
import { Link, NavLink, withRouter } from 'react-router-dom';

import resumeData from '../../utils/resumeData';
import CustomButton from '../Button/Button';


import './Header.css';


const Header = (props) => {
    const pathName = props?.location?.pathname;
    return (
			<Navbar expand="lg" sticky="top" className="header">
				{/* This is home link */}
				<Nav.Link as={NavLink} to="/" className="header_navlink">
					<Navbar.Brand className="header_home">
						<HomeRounded />
					</Navbar.Brand>
				</Nav.Link>

				<Navbar.Toggle />

				<Navbar.Collapse>
					<Nav className="header_left">
						{/* Resume Link */}
						<Nav.Link
							as={NavLink}
							to="/"
							className={pathName == "/" ? "header_link_active" : "header_link"}
						>
							Resume
						</Nav.Link>
						{/* Portfolio Link */}
						<Nav.Link
							as={NavLink}
							to="/portfolio"
							className={
								pathName == "/portfolio" ? "header_link_active" : "header_link"
							}
						>
							Portfolio
						</Nav.Link>

						{/* WorkExperience Link */}
						<Nav.Link
							as={NavLink}
							to="/workexperience"
							className={
								pathName == "/workexperience"
									? "header_link_active"
									: "header_link"
							}
						>
							Work Experience
						</Nav.Link>

						{/* Contact Link */}
						<Nav.Link
							as={NavLink}
							to="/contact"
							className={
								pathName == "/contact" ? "header_link_active" : "header_link"
							}
						>
							Contact
						</Nav.Link>
					</Nav>

					<div className="header_right">
						{Object.keys(resumeData.socials).map((key) => (
							<a href={resumeData.socials[key].link} target="_blank">
								{resumeData.socials[key].icon}
							</a>
						))}

						<a
							className="hireMe_link"
							href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=rk8521621032@gmail.com"
							target="_blank"
						>
							<CustomButton text={"Hire Me"} icon={<Telegram />} />
						</a>
					</div>
				</Navbar.Collapse>
			</Navbar>
		);
};

export default withRouter(Header);
