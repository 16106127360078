import React from 'react';
import Gallery from 'react-image-gallery';

import "react-image-gallery/styles/css/image-gallery.css";
import './imageGallery.css';

const ImageGallery = (props) => {
    const imageList = props?.images?.map((image) => {
        return {
            original: image,
            thumbnail: image,
            // fullscreen: 'https://images.pexels.com/photos/8335522/pexels-photo-8335522.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
        };
    });

    return (
        <div>
            <Gallery items={imageList} showBullets showThumbnails={true} />
        </div>
    );
};

export default ImageGallery;