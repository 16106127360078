import React from 'react';
import { Grid, TextField, Typography } from '@material-ui/core';
import resumeData from '../../utils/resumeData';
import './Contact.css';


import emailjs from 'emailjs-com';
import { EmailJSResponseStatus, init } from 'emailjs-com';
init("user_VjH7FslPNPRXmaTJVQ8g0");




export function sendEmail(e) {
    var myVal = e.target.email.value.length;
      console.log(myVal);
    e.preventDefault();
    if (myVal > 5) {
        emailjs.sendForm('service_k62w91s', 'template_gmg5fs6', e.target, 'user_VjH7FslPNPRXmaTJVQ8g0')
            .then((result) => {
                 alert("Message delivered successfully: 🙂");
            
            }, (error) => {
                console.log(error.text);
            });
    }
};
    

const Contact = () => {

    return (
        <>
            {/* Contacts  */}
            
            <Grid container spacing={6} className='section pt_45 pb_45' >
                {/* Contact Form */}
                <Grid item xs={12} lg={7} >
                    <Grid container>
                        <Grid item className='section_title mb_30'>
                            <span></span>
                            <h6 className='section_title_text'>Contact Form</h6>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container spacing={3} >
                                <form className="contact-form" onSubmit={sendEmail} >
                                    <div className='name_Email'>
                                        <Grid item xs={12} sm={6}>
                                            <TextField fullWidth name='name' label='Name' className='nameContact' />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField fullWidth name='email' type="email" label='Email' className='emailContact' />
                                        </Grid>
                                    </div>
                                    
                                    <Grid item xs={12} >
                                        <TextField fullWidth name='message' label='Message' multiline rows={3} className='messageContact' />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {/* <CustomButton  text='Submit'/> */}
                                        <input  type="submit" className='form_Button' value="Send" />
                                    </Grid>
                                </form>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                
                {/* Contact Information */}
                <Grid item xs={12} lg={5} >
                    <Grid container>
                        <Grid item className='section_title mb_30'>
                            <span></span>
                            <h6 className='section_title_text'>Contact Information</h6>
                        </Grid>
                        <Grid item xs={12}>
                        
                            <Grid container >
                                <Grid item xs={12}>
                                    <Typography className='contactsInfo_item'>
                                        <span>Address: </span> {resumeData.address}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className='contactsInfo_item'>
                                        <span>Email: </span> {resumeData.email}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className='contactsInfo_item'>
                                        <span>Phone: </span> {resumeData.mobile}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                       
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default Contact;
