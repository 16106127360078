import React from "react";
import { Grid, Paper, TextField, Typography } from "@material-ui/core";
import CustomTimeline, {
	CustomTimeLineSeparator,
} from "../../components/Timeline/Timeline";
import SchoolIcon from "@material-ui/icons/School";
import AssignmentTurnedInOutlinedIcon from "@material-ui/icons/AssignmentTurnedInOutlined";
import Icon from "@material-ui/core/Icon";

import resumeData from "../../utils/resumeData";
import "./Resume.css";
import { Timeline } from "@material-ui/icons";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import CustomButton from "../../components/Button/Button";

const Resume = () => {
	return (
		<>
			{/* About Me */}
			<Grid container className="section pb_45 pt_45">
				<Grid item className="section_title mb_30">
					<span></span>
					<h6 className="section_title_text">About Me</h6>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="body2" className="aboutme_text">
						{resumeData.about}
					</Typography>
				</Grid>
			</Grid>

			{/* Education and experiences */}

			<Grid container className="section pb_45">
				<Grid item className="section_title mb_30">
					<span></span>
					<h6 className="section_title_text">Resume</h6>
				</Grid>

				<Grid item xs={12}>
					<Grid container className="resume_timeline">
						{/* Educations */}
						<Grid item sm={12} md={6}>
							<CustomTimeline
								title="Education History"
								icon={<SchoolIcon style={{ fontSize: 30 }} />}
							>
								{resumeData.educations.map((education) => (
									<TimelineItem>
										<CustomTimeLineSeparator />
										<TimelineContent className="timeline_content">
											<Typography className="timeline_title">
												{education.title}
											</Typography>
											<Typography variant="caption" className="timeline_date">
												{education.date}
											</Typography>
											<Typography
												variant="body2"
												className="timeline_description"
											>
												{education.description}
											</Typography>
										</TimelineContent>
									</TimelineItem>
								))}
							</CustomTimeline>
						</Grid>

						{/* Certifications */}
						<Grid item sm={12} md={6}>
							<CustomTimeline
								title="Certifications"
								icon={
									<AssignmentTurnedInOutlinedIcon style={{ fontSize: 30 }} />
								}
							>
								{resumeData.certifications.map((certification) => (
									<TimelineItem>
										<CustomTimeLineSeparator />
										<TimelineContent className="timeline_content">
											<Typography className="timeline_title">
												{certification.title}
											</Typography>
											<Typography variant="caption" className="timeline_date">
												{certification.date}
											</Typography>
											<Typography
												variant="body2"
												className="timeline_description"
											>
												{certification.description}
											</Typography>
										</TimelineContent>
									</TimelineItem>
								))}
							</CustomTimeline>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			{/* My Services */}

			<Grid container className="section pb_45">
				<Grid item className="section_title mb_30">
					<span></span>
					<h6 className="section_title_text"> My Services</h6>
				</Grid>
				<Grid item xs={12}>
					<Grid container spacing={3} justify="space-around">
						{resumeData.services.map((service) => (
							<Grid item xs={12} sm={6} md={3}>
								<div className="service">
									<Icon className="service_icon"> {service.icon} </Icon>

									<Typography className="service_title" variant="h6">
										{service.title}
									</Typography>
									<Typography className="service_description" variant="body2">
										{service.description}
									</Typography>
								</div>
							</Grid>
						))}
					</Grid>
				</Grid>
			</Grid>

			{/* My Skills */}

			<Grid container className="section graybg pb_45 p_50 ">
				<Grid item xs={12}>
					<Grid container justify="space-between" spacing={3}>
						{resumeData.skills.map((skill) => (
							<Grid item xs={12} sm={6} md={3}>
								<Paper elevation={0} className="skill">
									<Typography variant="h6" className="skill_title">
										{skill.title}
										<hr></hr>
									</Typography>
									{skill.description.map((element) => (
										<Typography variant="body2" className="skill_description">
											<TimelineDot
												variant={"outlined"}
												className="timeline_dot"
											/>
											{element}
										</Typography>
									))}
								</Paper>
							</Grid>
						))}
					</Grid>
				</Grid>
			</Grid>
			{/* Contacts  */}
		</>
	);
};

export default Resume;
