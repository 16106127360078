import React from 'react';
import { Typography } from '@material-ui/core';
 
import { FaReact } from 'react-icons/fa'; 

import './Footer.css';


const Footer = () => {
    return (
        <div className='footer'>
            <div className='footer_left'>
                <Typography className='footer_name'>
                    <FaReact size='33px'/>
                </Typography>

            </div>
            <div className='footer_right'>
                <Typography className='footer_copyright'>
                    ©️ 2021 Designed and Developed by{" "}
                    <a href='https://www.linkedin.com/in/rohit-kumar-wdev/' target='_blank'>
                    Rohit </a>
                </Typography>
            </div>

        </div>
    );
};

export default Footer;
