import React from 'react';
import { Typography } from '@material-ui/core';
import rkImage from '../../assests/images/rohit.jpg';
import CustomTimeline, { CustomTimeLineSeparator } from '../Timeline/Timeline';
import resumeData from '../../utils/resumeData';

import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineContent from '@material-ui/lab/TimelineContent';

import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import GetAppIcon from '@material-ui/icons/GetApp';

import './Profile.css';
import CustomButton from '../Button/Button';

const CustomTimelineItem = ({ title, text, link }) => (
    
    <TimelineItem>
        <CustomTimeLineSeparator />
        <TimelineContent className="timeline_content">
            
            {link ? (
                <Typography className="timelineItem_text">
                    <span>{title}:</span> {" "}
                    <a href={link} target='_blank'>
                        {text}
                    </a>
                </Typography>
            ) : (
                
                < Typography className="timelineItem_text">
                <span>{title}:</span> {text}
                </Typography>
            )}
        </TimelineContent>
    </TimelineItem>
);

    
const Profile = () => {
    return (
			<div className="profile container_shadow">
				<div className="profile_name">
					<Typography className="name">{resumeData.name}</Typography>
					<Typography className="title">{resumeData.title}</Typography>
				</div>
				<figure className="profile_image">
					<img src={rkImage} alt="Rohit Image" />
				</figure>
				<div className="profile_information">
					<CustomTimeline icon={<PersonOutlinedIcon />}>
						<CustomTimelineItem title="Name" text={resumeData.name} />
						<CustomTimelineItem title="Title" text={resumeData.title} />
						<CustomTimelineItem title="Contact" text={resumeData.contact} />

						{Object.keys(resumeData.socials).map((key) => (
							<CustomTimelineItem
								title={key}
								text={resumeData.socials[key].text}
								link={resumeData.socials[key].link}
							/>
						))}
					</CustomTimeline>
					{/* <br /> */}

					<div className="button_container">
						<a
							className="hireMe_link"
							href="https://drive.google.com/file/d/1iguqhmEdUdbAdv0CEhbonQsjs0lf7FmF/view?usp=sharing"
							target="_blank"
						>
							<CustomButton text={"Download Cv"} icon={<GetAppIcon />} />
						</a>
					</div>
				</div>
			</div>
		);
}

export default Profile;

