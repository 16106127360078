import React from 'react';

import LinkedInIcon from '@material-ui/icons/LinkedIn';
// import GitHubIcon from '@material-ui/icons/GitHub';
import InstagramIcon from '@material-ui/icons/Instagram';
import EmailIcon from '@material-ui/icons/Email';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import WebIcon from '@material-ui/icons/Web';
import { GitHub, Language, ShoppingCart } from '@material-ui/icons';
import { GiDeliveryDrone } from 'react-icons/gi';
import { FaSearchengin} from 'react-icons/fa';


// import Screen from '../utils/mySc.jpg';
import { covid19,chatApp,eCommerce, covid_1, covid_2, covid_3, covid_4,chatApp_1,chatApp_2,chatApp_3,chatApp_4,chatApp_5,chatApp_6,chatApp_7,shop_1,shop_2,shop_3,shop_4,shop_5,shop_6,shop_7,shop_8  }  from '../images';

export default {
	name: "Rohit Kumar",
	title: "Front-End Developer",

	Birthday: "5th February 1998",
	contact: "+91 8521621032",
	email: "rk8521621032@gmail.com",
	mobile: "8521621032",
	address: "Daltonganj , Palamu ,Jharkhand,822124",

	socials: {
		LinkedIn: {
			link: "https://www.linkedin.com/in/rohit-kumar-wdev/",
			text: "ʟɪɴᴋᴇᴅɪɴɪᴅ",
			icon: <LinkedInIcon />,
		},
		Gmail: {
			link: "https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=rk8521621032@gmail.com",
			text: "ɢᴍᴀɪʟɪᴅ",
			icon: <MailOutlineIcon />,
		},
		Instagram: {
			link: "https://www.instagram.com/rohit.niit/",
			text: "ɪɴꜱᴛᴀɢʀᴀᴍɪᴅ",
			icon: <InstagramIcon />,
		},
	},

	about:
		"Hello. I'm Rohit a 𝐖𝐞𝐛 𝐝𝐞𝐬𝐢𝐠𝐧𝐞𝐫 and 𝐅𝐫𝐨𝐧𝐭-𝐄𝐧𝐝 𝐃𝐞𝐯𝐞𝐥𝐨𝐩𝐞𝐫 from India, who loves designing and the internet sphere. Having a serious focused vision  on crafting clean, dynamic and user-friendly experiences. I have good hands-on experience in web development for the past three years. I prefer to keep learning, continue challenging myself, and do interesting things.",

	educations: [
		{
			title: "Silicon Institute of Technology (CSE)",
			date: "2016-2020",
			description:
				"Completed graduation in Bachelor of Technology (B.Tech) with 𝐂𝐨𝐦𝐩𝐮𝐭𝐞𝐫 𝐒𝐜𝐢𝐞𝐧𝐜𝐞 𝐚𝐧𝐝 𝐄𝐧𝐠𝐢𝐧𝐞𝐞𝐫𝐢𝐧𝐠 stream.It all started with Fundamentals of Programming to variety of other important skills.",
		},
		{
			title: "C S D Inter College",
			date: "2013-2015",
			description:
				"Completed 𝐈𝐧𝐭𝐞𝐫𝐦𝐞𝐝𝐢𝐚𝐭𝐞 𝐰𝐢𝐭𝐡 𝐒𝐜𝐢𝐞𝐧𝐜𝐞 (𝐌𝐚𝐭𝐡𝐞𝐦𝐚𝐭𝐢𝐜𝐬) stream.Learned to support ideas and opinions using specific examples.",
		},
		{
			title: "R K Surat High School",
			date: "2006-2013",
			description:
				"Completed my schooling and learned to think through different scenarios and situations that inevitably arise in our lives and many other basic life skills. ",
		},
	],

	work_experience: [
		{
			title: "Bookingjini (React JS Developer)",
			date: "Aug-2021 - Present",
			description:
				"We are creating Booking Engines for different hotels which want to reduce their dependency on OTAs. My role is to create different functional components, fetch the data from APIs, and link all pages with the help of React-Router, by following the received design Templates.",
		},
		{
			title: "Trigital Technologies Pvt Ltd (React JS Developer) ",
			date: " Jun-2021 - Aug-2021 ",
			description:
				"Worked under the supervision of tech lead and contributed to the making of e-Parisheva website.",
		},
		{
			title: "Freelance Web Developer (Web Developer)",
			date: " Sept-2020 - June-2021",
			description: " ",
		},
	],

	certifications: [
		{
			title: "React JS",
			date: "Complete Guide from scratch!",
			description:
				"Started with core, How 𝐑𝐞𝐚𝐜𝐭 works, 𝐛𝐮𝐢𝐥𝐝𝐢𝐧𝐠 𝐜𝐨𝐦𝐩𝐨𝐧𝐞𝐧𝐭𝐬 with react and learned about Components, props & Dynamic data binding, user events and state, 𝐑𝐨𝐮𝐭𝐢𝐧𝐠 𝐰𝐢𝐭𝐡 𝐑𝐞𝐚𝐜𝐭 𝐑𝐨𝐮𝐭𝐞𝐫",
		},
		{
			title: "React Hooks,  Redux",
			date: "React with hooks and Redux training",
			description:
				"How to use and be effective with the 𝐦𝐚𝐣𝐨𝐫 𝐡𝐨𝐨𝐤𝐬 (useState, useEffect, useReducer, useContext, useRef).Managing state transitions with the useReducer hook instead of 𝐑𝐞𝐝𝐮𝐱.",
		},
		{
			title: "Advanced JavaScript",
			date: "Covered Advance Concepts",
			description:
				"Learned about Functional Programming, 𝐒𝐜𝐨𝐩𝐞 and Execution Context, important JavaScript methods, `this` keyword, Event Loop, 𝐂𝐥𝐨𝐬𝐮𝐫𝐞𝐬, 𝐀𝐬𝐲𝐧𝐜𝐡𝐫𝐨𝐧𝐨𝐮𝐬 JavaScript, Hoisting.",
		},
	],

	services: [
		{
			title: "Responsive Design",
			description:
				"Responsive eye catchy websites, suits to different screen widths and resolutions.",
			icon: <WebIcon />,
		},
		{
			title: "SEO-Friendly",
			description:
				"Well formatted, interpret the content effectively, improve your page’s overall performance.",
			icon: <FaSearchengin size="46px" />,
		},
		{
			title: "Fast Delivery",
			description:
				"For faster delivery, do reach out. I wii be more than happy to help. 🙂 ",
			icon: <GiDeliveryDrone size="46px" />,
		},
	],
	skills: [
		{
			title: "ʟɪʙʀᴀʀɪᴇꜱ /ꜰʀᴀᴍᴇᴡᴏʀᴋꜱ",
			description: [
				"React.js",
				"React Hooks ",
				"Redux",
				"React Router",
				"Bootstrap",
				"Material-Ui",
				"Commerce.JS",
				"email.js",
				"git",
			],
			icon: <InstagramIcon />,
		},

		{
			title: "ᴘʀᴏɢʀᴀᴍᴍɪɴɢ /ᴍᴀʀᴋᴜᴘ ʟᴀɴɢᴜᴀɢᴇꜱ ",
			description: ["JavaScript", "Python", "C", "HTML5", "CSS"],
			icon: <InstagramIcon />,
		},
		{
			title: "ᴄᴏɴᴛᴇɴᴛ ᴍᴀɴᴀɢᴇᴍᴇɴᴛ ꜱʏꜱᴛᴇᴍ",
			description: ["WordPress", "Wix", "Blogger"],
			icon: <InstagramIcon />,
		},
		// {
		//     title: 'Front-End',
		//     description: ['ReactJS','JavaScript','Redux','CommerceJs','Bootstrap'],
		//     icon:  <InstagramIcon />
		// }
	],

	projects: [
		{
			tag: "ReactJS",
			backGrImage: covid19,
			images: [covid_1, covid_2, covid_3, covid_4],
			// title: 'Project 1',
			caption: "COVID-19 Tracker Application",
			// description: 'This is the PRoject description please fill it carefully it will help you to get hired',
			links: [
				{
					link: "https://coronavirus-live-updates.netlify.app",
					icon: <Language />,
				},
			],
		},

		{
			tag: "ReactJS",
			backGrImage: eCommerce,
			images: [shop_1, shop_2, shop_3, shop_4, shop_5, shop_6, shop_7, shop_8],
			// title: 'Project 3',
			caption: "E-Commerce Web Shop",
			// description: 'This is the PRoject description please gge fill it carefully it will help you to get hired',
			links: [
				{
					link: "https://better-buys-collections.netlify.app",
					icon: <Language />,
				},
			],
		},

		{
			tag: "ReactJS",
			backGrImage: chatApp,
			images: [
				chatApp_1,
				chatApp_2,
				chatApp_3,
				chatApp_4,
				chatApp_5,
				chatApp_6,
				chatApp_7,
			],
			// title: 'Project 2',
			caption: "Chat Application using React JS",
			// description: 'This is the PRoject description please fill it carefully it will help you to get hired',
			links: [
				{
					link: "https://privatechat-app.netlify.app",
					icon: <Language />,
				},
			],
		},

		// {
		// tag: 'Responsive designs',
		// backGrImage: chatApp,
		// images: ['https://api.time.com/wp-content/uploads/2020/04/CoronavirusFAQOpener.jpg?w=800&quality=85',
		//          'https://images.unsplash.com/photo-1483985988355-763728e1935b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1500&q=80',
		//         ],
		// title: 'Project 2',
		// caption: 'A Short Description',
		// description: 'This is the PRoject description please fill it carefully it will help you to get hired',
		// links: [
		//     {
		//         link: 'https://privatechat-application-using-reactjs.netlify.app', icon: <ShoppingCart/>
		//     },
		//     {
		//         link: 'https://privatechat-application-using-reactjs.netlify.app', icon: <GitHub/>
		//     },
		//     {
		//         link: 'https://privatechat-application-using-reactjs.netlify.app', icon: <Language/>
		//     }
		// ]
		// },
	],
};