import React from "react";
import { Grid, Paper, TextField, Typography } from "@material-ui/core";
import CustomTimeline, {
	CustomTimeLineSeparator,
} from "../../components/Timeline/Timeline";
// import SchoolIcon from "@material-ui/icons/School";
import WorkIcon from "@material-ui/icons/Work";
// import WorkIcon from "@mui/icons-material/Work";
// import AssignmentTurnedInOutlinedIcon from "@material-ui/icons/AssignmentTurnedInOutlined";
// import Icon from "@material-ui/core/Icon";

import resumeData from "../../utils/resumeData";
import "./WorkExperience.css";
// import { Timeline } from "@material-ui/icons";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineContent from "@material-ui/lab/TimelineContent";
// import TimelineDot from "@material-ui/lab/TimelineDot";
// import CustomButton from "../../components/Button/Button";

const WorkExperience = () => {
    return (
			<>
				<Grid container className="section pb_45">
					<Grid item xs={12}>
						<Grid container className="resume_timeline">
							<Grid item sm={12} md={10} style={{marginTop:"25px"}}>
								<CustomTimeline
									title="Work Experience"
									icon={<WorkIcon style={{ fontSize: 30 }} />}
								>
									{resumeData.work_experience.map((education) => (
										<TimelineItem>
											<CustomTimeLineSeparator />
											<TimelineContent className="timeline_content">
												<Typography className="timeline_title">
													{education.title}
												</Typography>
												<Typography variant="caption" className="timeline_date" style={{fontWeight:"bold"}}>
													{education.date}
												</Typography>
												<Typography
													variant="body2"
													className="timeline_description"
												>
													{education.description}
												</Typography>
											</TimelineContent>
										</TimelineItem>
									))}
								</CustomTimeline>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</>
		);
};

export default WorkExperience;
