import React from "react";
import { Container, Grid } from "@material-ui/core";
import "./App.css";

import Profile from "./components/Profile/Profile";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Portfolio from "./pages/Portfolio/Portfolio";
import Resume from "./pages/Resume/Resume";
import Contact from "./pages/Contact/Contact";
import WorkExperience from "./pages/WorkExperience/WorkExperience";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {
	return (
		<Container className={"top_60"}>
			<Grid container spacing={7}>
				<Grid item xs={12} sm={12} md={4} lg={3}>
					<Profile />
				</Grid>

				<Grid item xs>
					{/* here by writting xs it takes remainig grid space  after first Grid */}

					<Router>
						<Header />
						<div className="main_content container_shadow ">
              <Switch>
                
								<Route exact path="/">
									<Resume />
								</Route>
								<Route path="/Portfolio">
									<Portfolio />
								</Route>

								<Route path="/Contact">
									<Contact />
								</Route>

								<Route path="/WorkExperience">
									<WorkExperience />
								</Route>
							</Switch>
						</div>
					</Router>

					<Footer />
				</Grid>
			</Grid>
		</Container>
	);
}

export default App;
