import React from 'react';
import { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import './Portfolio.css';
import { Card, CardActionArea, CardContent, CardMedia,Dialog, DialogActions,DialogContent, DialogTitle, Grow, Tabs, Typography } from '@material-ui/core';
import { Tab } from '@material-ui/core';

import resumeData from '../../utils/resumeData';
import ImageGallery from '../../components/imageGallery/imageGallery';
import { Title } from '@material-ui/icons';

const Portfolio = () => {

    const [tabValue, setTabValue] = useState("All");
    const [projectDialog, setprojectDialog] = useState(false);

    const ProjectDialog = () => {
        
    }
    return (
        <Grid container spacing={1} className='section pb_45 pt_45'   >
            
            {/* title */}

            <Grid item className='section_title mb_20'>
                <span></span>
                <h6 className='section_title_text'> Portfolio </h6>
            </Grid>

            {/* Tabs */}
            <Grid item xs={12}>
                <Tabs value={tabValue}
                    indicatorColor='white'
                    className='customTabs'
                    onChange={(event, newValue) => setTabValue(newValue)} >
                    
                    {/* if tabValue = All then className = 'customTabs_item active'  otherwise = 'customTabs_item' */}

                    <Tab label='All' value='All'
                        className={tabValue == 'All' ? 'customTabs_item active' : 'customTabs_item'}
                        
                    />
                    {/* Set in JS contain unique values */}

                    {/* Putting all values in array and mapping it on second map() */}

                    {[... new Set(resumeData.projects.map(item => item.tag))].map(tag => (

                        <Tab label={tag} value={tag}
                         
                         className={tabValue == tag ? 'customTabs_item active' : 'customTabs_item'}

                        />
                    ))}

                    
    
                </Tabs>
            </Grid>
            
            {/* Projects */}

            <Grid item xs={12}>
                <Grid container spacing={4} >
                    {resumeData.projects.map((project) => (
                        <>
                            {tabValue == project.tag || tabValue == "All" ? (

                            <Grid item xs ={12} sm={6} md ={4} >
                                    <Grow in timeout={2000}>
                                        
                            <Card className='customCard'
                             onClick={() => setprojectDialog(project)}>
                                <CardActionArea>
                                    <CardMedia className= 'customCard_image' image={project.backGrImage} title= {project.title} />
                                    
                                    <CardContent >
                                        <Typography variant={'body2'} className='customCard_title'> {project.title}  </Typography>
                                        <Typography variant = 'caption' className='customCard_caption'> { project.caption} </Typography>
                                    </CardContent>
                                </CardActionArea>
                           </Card>
                            </Grow>
                            
                        </Grid>
                        ): null}
                        
                    </>
                        
                    ))}

                </Grid>
            </Grid>

            <Dialog
                open={projectDialog} onClose={() => setprojectDialog(false)} className='projectDialog'  fullWidth>
               
                <DialogTitle onClose={() => setprojectDialog(false)}>
                    {projectDialog.title}
                
                </DialogTitle>
                
                {/* <img src={projectDialog.images} alt=" " className= 'projectDialog_image' /> */}
                <DialogContent style={{ height: '80vh' }}>
                    
                    {projectDialog.images && (

                        <ImageGallery images={projectDialog.images}/>
                    )}
                    
                    {/* <Typography className='projectDialog_description'> {projectDialog.description} </Typography> */}
                    
                </DialogContent>

                <DialogActions className='projectDialog_actions'>
                    {projectDialog?.links?.map(link => (

                    <a href={link.link} target='_blank' className='projectDialog_icon'> {link.icon} </a>
                    ))}
                </DialogActions>

            </Dialog>
            
        </Grid>
    );
};

export default Portfolio;

